import React from "react"

import "../styles/footer.scss"

const Footer = () => {
  return (
    <footer>
          © {new Date().getFullYear()} <a href="https://aviary.tech">Aviary Technologies Inc.</a>
    </footer>
  )
}

export default Footer
